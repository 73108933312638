// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/assets/icons/OneTreeIcon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/assets/icons/OneTreeIcon.tsx");
  import.meta.hot.lastModified = "1691593351472.7766";
}
// REMIX HMR END

export function OneTreeIcon({
  className
}) {
  return <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4914_58397)">
        <g filter="url(#filter0_d_4914_58397)">
          <path d="M9 14H11C11 14 11 15 11 17C11 19 11.5 20 11.5 20H8.5C8.5 20 9 19 9 17C9 15 9 14 9 14Z" fill="url(#paint0_linear_4914_58397)" />
        </g>
        <g filter="url(#filter1_d_4914_58397)">
          <circle cx="5.5" cy="8.5" r="3.5" fill="currentColor" />
        </g>
        <g filter="url(#filter2_d_4914_58397)">
          <circle cx="8" cy="12" r="3" fill="currentColor" />
        </g>
        <circle cx="13" cy="11" r="4" fill="currentColor" />
        <circle cx="15.5" cy="7.5" r="2.5" fill="currentColor" />
        <g filter="url(#filter3_d_4914_58397)">
          <circle cx="9" cy="6" r="4" fill="currentColor" />
        </g>
        <circle cx="12.5" cy="5.5" r="3.5" fill="currentColor" />
      </g>
      <defs>
        <filter id="filter0_d_4914_58397" x="4.5" y="14" width="11" height="14" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4914_58397" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4914_58397" result="shape" />
        </filter>
        <filter id="filter1_d_4914_58397" x="-2" y="5" width="15" height="15" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4914_58397" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4914_58397" result="shape" />
        </filter>
        <filter id="filter2_d_4914_58397" x="1" y="9" width="14" height="14" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4914_58397" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4914_58397" result="shape" />
        </filter>
        <filter id="filter3_d_4914_58397" x="1" y="-2" width="16" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4914_58397" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4914_58397" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_4914_58397" x1="10" y1="14" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#402309" />
          <stop offset="1" stopColor="#713F12" />
        </linearGradient>
        <clipPath id="clip0_4914_58397">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>;
}
_c = OneTreeIcon;
var _c;
$RefreshReg$(_c, "OneTreeIcon");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;